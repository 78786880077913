import type { MutationHookOptions } from "@apollo/client";
import { gql, useMutation } from "@apollo/client";

import type { AuthUserTwoFactorAuthenticationVerifyPayload } from "@shared/types/graphqlGenerated";

const VerifyTwoFactorAuthentication_UnauthenticatedShared_Mutation = gql`
  mutation VerifyTwoFactorAuthentication_UnauthenticatedShared_Mutation(
    $input: AuthUserTwoFactorAuthenticationVerifyInput!
  ) {
    auth {
      userVerifyTwoFactorAuthentication(input: $input) {
        locked
        redirectPath
        returnToSignin
        newBackupCode
        roleType
        errors {
          message
        }
      }
    }
  }
`;

interface VerifyTwoFactorAuthData {
  auth: {
    userVerifyTwoFactorAuthentication: Omit<
      AuthUserTwoFactorAuthenticationVerifyPayload,
      "clientMutationId" | "user"
    >;
  };
}

interface Variables {
  input: {
    attributes: {
      otp: string;
      token: string;
      trustThisDevice: boolean;
    };
  };
}

const useVerifyTwoFactorAuthenticationMutation = (
  options?: MutationHookOptions<VerifyTwoFactorAuthData, Variables>
) =>
  useMutation<VerifyTwoFactorAuthData, Variables>(
    VerifyTwoFactorAuthentication_UnauthenticatedShared_Mutation,
    options
  );

export {
  useVerifyTwoFactorAuthenticationMutation,
  VerifyTwoFactorAuthentication_UnauthenticatedShared_Mutation,
};
