import { setContext } from "@apollo/client/link/context";
import { getFingerprint } from "@thumbmarkjs/thumbmarkjs";

let fingerprint;
/**
 * An apollo link that sets X-FS-CLIENT-FINGERPRINT header which contains
 * the current device fingerprint
 */
export const fingerprintLink = setContext(async (_, { headers }) => {
  if (!fingerprint) {
    fingerprint = await getFingerprint();
  }

  return {
    headers: {
      ...headers,
      "x-fs-client-fingerprint": fingerprint,
    },
  };
});
